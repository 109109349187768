import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["assignRdlForm", "filePreviewModal"]

  connect(){
    $('.rdl-selection .dropdown').dropdown();
  }

  assignRdl(){
    let form = $(this.assignRdlFormTarget)
    let fileUploadPreviewModal = $(this.filePreviewModalTarget)
    let fileUploadName = form.closest(".file-upload-item").find("span.preview-file-upload").text().trim()
    let selectedDocumentTitle = form.find('select').find(":selected").text()

    this.assignRdlFormTarget.addEventListener("turbo:submit-end", (event) => {
      fileUploadPreviewModal.modal('hide')

      if(event.detail.success){
        toastr.success(`<b>${fileUploadName}</b> assigned to <b>${selectedDocumentTitle}</b>`)
      } else {
        toastr.error(`Failed to assign ${fileUploadName}`)
      }
    })
  }
}
