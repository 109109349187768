import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["uploadableToggle", "checkbox"]

  connect(){
    this._bindFileUploadPreviewModal()
  }

  toggleUploadable(event) {
    this.uploadableToggleTarget.style.pointerEvents = "none";
    let url = this.data.get("toggle-uploadable-path");
    Rails.ajax({
      type: 'PATCH',
      url: url,
      complete: (res) => {
        this.uploadableToggleTarget.style.pointerEvents = "auto";
        var enabled = event.target.checked
        if(res.status != 204){
          event.target.checked = !event.target.checked
          toastr.error("Failed to toggle client file uploads");
        } else {
          toastr.success(i18n.t("activerecord.errors.models.document_dropbox.attributes.uploadable." + ( enabled ? "enabled" : "disabled" )));
        }
      }
    });
  }

  _bindFileUploadPreviewModal(){
    $('.document-dropbox .preview-file-upload').on("click", function () {
        var fileUploadId = $(this).data("file-upload-id");
        var fileUploadPreviewModal = $('#document-dropbox-tab .file-upload-preview[data-file-upload-id="' + fileUploadId + '"]')
        fileUploadPreviewModal.modal({
          detachable: false,
          onShow: function(){
            var previewFileUploadContainer = this.closest('.file-upload-preview-container');
            $(previewFileUploadContainer).removeClass('hidden');
            $(this).removeClass('legacy');
            $(previewFileUploadContainer).addClass('visible');
          },
          onHide: function(){
            var previewFileUploadContainer = this.closest('.file-upload-preview-container');
            $(previewFileUploadContainer).removeClass('visible');
            $(previewFileUploadContainer).addClass('hidden');
          }
        }).modal('show');
    });
  }
}
